import React from "react";
import Layout from "components/layout";
import Seo from "components/seo/seo";
import FastDeliveryForm from "components/growth/forms/live-pharma";

import bgCustomers from "images/lives/frame.png";

import BimboLogo from "images/growth-brand-logos/bimbo.png";
import CruzVerdeLogo from "images/growth-brand-logos/cruzverde.png";
import FarmatodoLogo from "images/growth-brand-logos/farmatodo.png";
import JumboLogo from "images/growth-brand-logos/jumbo.png";
import EasyLogo from "images/growth-brand-logos/easy.png";
import MercadoLibreLogo from "images/growth-brand-logos/merecadolibre.png";
import NestleLogo from "images/growth-brand-logos/nestle.png";
import OxxoLogo from "images/growth-brand-logos/oxxo.png";
import RappiLogo from "images/growth-brand-logos/rappi.png";
import TugoLogo from "images/growth-brand-logos/tugo.png";

import ListCheckIcon from "images/list-check-icon.svg";

import LastMileImg from "images/lives/last-mile.svg";
import SameDayImg from "images/lives/same-day.svg";
import ReturnImg from "images/lives/return.svg";

import "pages/growth/fast-delivery.css";

const logos = [
  TugoLogo,
  JumboLogo,
  RappiLogo,
  EasyLogo,
  NestleLogo,
  MercadoLibreLogo,
  BimboLogo,
  CruzVerdeLogo,
  FarmatodoLogo,
  OxxoLogo,
];

const Index = () => (
  <Layout className="fast-delivery">
    <Seo 
      title='liftit' 
      description='make same day deliveries'
    />
    <div className="global-wrapper">
      <head>
        <h4>Liftit Lives</h4>
        <h1>Pharma; Salvando vidas sobre ruedas.</h1>

        <p>
          <b>¿Has atravesado desafíos para abastecer a tus clientes?</b>Estaremos en vivo este jueves 26 de agosto a las 12:00 p.m. hora Colombia, con Farmatodo, Cruz Verde, Grupo BOMI y Evedisa hablando sobre los desafíos que han atravesado para salvar vidas mediante el abastecimiento de medicamentos en medio de la crisis social y cómo han logrado suplir las necesidades médicas.

        </p>

        <ul style={{ listStyleImage: `url(${ListCheckIcon})` }}>
          <li>¿Cómo controlar la eficiencia de la cadena de distribución en tiempos de crisis?</li>
          <li>¿Cómo cumplir con las entregas superando los retos de la última milla?</li>
          <li>¿Qué estrategias se deben adoptar para entregar pedidos el mismo día?</li>
        </ul>
      </head>

      <div className="form">
        <FastDeliveryForm />
      </div>

      <div
        className="customers"
        style={{ backgroundImage: `url(${bgCustomers})` }}
      >
        <span>Ellos confían en nosotros</span>
        <div className="brands">
          {logos.map((logo) => (
            <img src={logo} alt={logo} />
          ))}
        </div>
      </div>

      <div className="features">
        <h4>Este conversatorio te ayudará a estructurar tu propia estrategia.</h4>

        <div className="feature">
          <img src={LastMileImg} alt="Tecnologia" />
          <h5 className="feature-title">Última milla</h5>
          <p>
          Domina grandes volúmenes de entrega con cumplimiento garantizado, brindando una experiencia de entrega a tus clientes diferenciada aumentando la probabilidad de recompra.
          </p>
        </div>
        <div className="feature">
          <img src={SameDayImg} alt="Operatividad" />
          <h5 className="feature-title">Same Day</h5>
          <p>
            Flexibiliza tus entregas a través de nuestra amplia flota de
            vehículos y brinda a tus clientes una excelente experiencia de
            compra y entrega para fidelizarlos
          </p>
        </div>
        <div className="feature">
          <img src={ReturnImg} alt="Servicio" />
          <h5 className="feature-title">Logistica Inversa</h5>
          <p>
            Notifica a tus clientes el estado de la entrega, permiteles ubicar
            fácilmente sus productos y extiende tu marca través del trato
            profesionalizado de nuestros Lifters{" "}
          </p>
        </div>
      </div>
    </div>
  </Layout>
);

export default Index;
